
import { Report } from '@/wms/domain/reports/ReportConfig';
import Component from 'vue-class-component';
import { Emit, Prop } from 'vue-property-decorator';
import Vue from 'vue';

@Component({
  name: 'ReportForm'
})
export default class ReportForm extends Vue {
  @Prop() reportList!: Report[];
  @Prop() selectedReportType!: Report | null;
  @Prop() accountLower!: string;
  @Prop() accountUpper!: string;
  @Prop() groupLower!: string;
  @Prop() groupUpper!: string;
  @Prop() year!: string;
  @Prop() month!: string;
  @Prop() customerCode!: string;
  @Prop() lowerMonth!: string;
  @Prop() upperMonth!: string;
  @Prop() filePath!: string;
  @Prop() costCenterLower!: string;
  @Prop() costCenterUpper!: string;
  @Prop() ledgerAccountLower!: string;
  @Prop() ledgerAccountUpper!: string;
  @Prop() handleGenerate!: () => Promise<string>;
  @Prop() updateReport!: (report: Report) => Promise<Report>;

  // Local data

  // Emit the update for selectedReportType
  @Emit('update:selected-report-type')
  updateSelectedReportType(value: string) {
    return value;
  }

  @Emit('update:year')
  /*************  ✨ Codeium Command ⭐  *************/
  /**
   * Emit the update for year
   * @param value The year number
   * @returns The year number
   */
  /******  29e66c17-eb3f-496b-b678-b178114e67b5  *******/
  updateYear(value: number) {
    return value;
  }

  @Emit('update:lower-month')
  updateLowerMonth(value: number) {
    return value;
  }
  @Emit('update:upper-month')
  updateUpperMonth(value: number) {
    return value;
  }

  @Emit('update:account-lower')
  updateAccountLower(value: string) {
    return value;
  }

  @Emit('update:account-upper')
  updateAccountUpper(value: string) {
    return value;
  }

  @Emit('update:group-lower')
  updateGroupLower(value: string) {
    return value;
  }

  @Emit('update:group-upper')
  updateGroupUpper(value: string) {
    return value;
  }

  @Emit('update:cost-center-lower')
  updateCostCenterLower(value: string) {
    return value;
  }

  @Emit('update:cost-center-upper')
  updateCostCenterUpper(value: string) {
    return value;
  }

  @Emit('update:ledger-account-lower')
  updateLedgerAccountLower(value: string) {
    return value;
  }

  @Emit('update:ledger-account-upper')
  updateLedgerAccountUpper(value: string) {
    return value;
  }

  @Emit('update:month')
  updateMonth(value: string) {
    return value;
  }

  @Emit('update:customer-code')
  updateCustomerCode(value: string) {
    return value;
  }
}
