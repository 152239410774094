
import { TYPES } from '@/core/config/Types';
import { CreateFileV2 } from '@/settings/application/uses_cases/file/create/CreateFileV2';
import { FindAllReports } from '@/wms/application/reports/search/FindAllReports';
import { GenerateReports } from '@/wms/application/reports/search/GenerateReport';
import { UpdateReports } from '@/wms/application/reports/update/UpdateReport';
import { Report } from '@/wms/domain/reports/ReportConfig';
import { Inject } from 'inversify-props';
import Vue from 'vue';
import Component from 'vue-class-component';
import ReportForm from './ReportForm.vue';

@Component({
  name: 'ReportCenter',
  components: { ReportForm }
})
export default class ReportCenter extends Vue {
  @Inject(TYPES.REPORT_FIND_ALL) reportFindAll!: FindAllReports;
  @Inject(TYPES.REPORT_UPDATE) reportUpdate!: UpdateReports;
  @Inject(TYPES.REPORT_GENERATE) reportGenerate!: GenerateReports;
  @Inject(TYPES.SAVE_FILE) saveFile!: CreateFileV2;

  selectedReportType = '';
  reportList: Report[] = [];
  reportTypeToEdit = '';
  accountLower = '';
  accountUpper = '';
  groupLower = '';
  groupUpper = '';
  year = new Date().getFullYear();
  month = new Date().getMonth() + 1;
  customerCode = '';
  ledgerAccountLower = '';
  ledgerAccountUpper = '';
  lowerMonth = 1;
  upperMonth = 13;
  filePath = '';
  costCenterLower = '';
  costCenterUpper = '';
  isLoading = false;
  file: File | null = null;

  mounted() {
    this.findAllReports();
  }

  get reportOptions() {
    return this.reportList.map(report => ({
      value: report.type,
      text: this.$t(`Report.${report.type}`)
    }));
  }

  handleReportTypeChange(selectedType: string) {
    const selectedReport = this.reportList.find(report => report.type === selectedType);
    this.reportTypeToEdit = selectedReport?.type || '';
  }

  async handleGenerate() {
    this.isLoading = true;
    const filtersPayload = {
      type: this.selectedReportType ? this.selectedReportType : '',
      filters: {
        YEAR: Number(this.year),
        MONTH: Number(this.month),
        LOWER_MONTH: Number(this.lowerMonth),
        UPPER_MONTH: Number(this.upperMonth),
        LOWER_GROUP: this.groupLower.length === 0 ? '1' : this.groupLower,
        UPPER_GROUP: this.groupUpper.length === 0 ? '999999999' : this.groupUpper,
        LOWER_ACCOUNT_GROUP: this.accountLower.length === 0 ? '1' : this.accountLower,
        UPPER_ACCOUNT_GROUP: this.accountUpper.length === 0 ? '999999999' : this.accountUpper,
        LOWER_COST_CENTER: this.costCenterLower.length === 0 ? '1' : this.costCenterLower,
        UPPER_COST_CENTER: this.costCenterUpper.length === 0 ? '999999999' : this.costCenterUpper,
        LOWER_ACCOUNT: this.ledgerAccountLower.length === 0 ? '1' : this.ledgerAccountLower,
        UPPER_ACCOUNT: this.ledgerAccountUpper.length === 0 ? '999999999' : this.ledgerAccountUpper,
        CUSTOMER_CODE: Number(this.customerCode)
      }
    };
    try {
      const res = await this.reportGenerate.execute(filtersPayload);
      const link = document.createElement('a');
      link.href = res;
      link.setAttribute('download', `Report_${this.selectedReportType}.pdf`);
      document.body.appendChild(link);
      link.click();

      // Limpiamos la URL generada
      window.URL.revokeObjectURL(res);
    } catch (error) {
      throw new Error(`${error}`);
    } finally {
      this.isLoading = false;
    }
  }

  async findAllReports(): Promise<Report[]> {
    const res = await this.reportFindAll.execute();
    this.reportList = res;
    return res;
  }

  async uploadFile(file: File): Promise<string> {
    //  Creamos nuevo archivo con el nombre modificado
    const newFile = new File([file], file.name, { type: file?.type });
    const res = await this.saveFile.execute({ files: [newFile], folder: 'custom-reports' });
    if (Array.isArray(res)) {
      return '';
    }
    this.filePath = res;
    return res;
  }

  async handleUpdateReport() {
    const fileUrl = this.file ? await this.uploadFile(this.file) : '';
    const payload = {
      type: this.reportTypeToEdit,
      url: fileUrl
    };
    return await this.reportUpdate.execute(payload);
  }

  setFile(file: File | null) {
    this.$nextTick(() => {
      this.file = file;
    });
  }

  onFileUploadClick() {
    (this.$refs.fileInput as HTMLElement)?.click();
  }
  handleFileChange(event: InputEvent) {
    const file = (event.target as HTMLInputElement)?.files?.[0] ?? null;
    this.setFile(file);
  }
}
